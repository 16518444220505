const $mobileNavigationContent = document.getElementById(
  "mobile-navigation-content"
);
const $mobileNavigationList = document.getElementById("mobile-navigation-list");
const a = document.getElementById("a");

const $mobileNavigationBurger = document.getElementById(
  "mobile-navigation-burger"
);

const classList = $mobileNavigationContent.classList;

function toggleNavigationMenu() {
  classList.contains("navigation__content_hidden")
    ? classList.remove("navigation__content_hidden")
    : classList.add("navigation__content_hidden");
}

function handleWindowClick(event) {
  if (classList.contains("navigation__content_hidden")) return;
  if (event.target === $mobileNavigationContent) return;
  if (event.target === $mobileNavigationList) return;
  if (event.target === $mobileNavigationBurger) return;
  if (event.target.classList.contains("navigation__link")) {
    setTimeout(function () {
      event.target.click();
    }, 0);
  }
  toggleNavigationMenu();
}

window.addEventListener("click", handleWindowClick);
window.addEventListener("touchend", handleWindowClick);

$mobileNavigationBurger.addEventListener("click", toggleNavigationMenu);
